



































import { Component, Vue } from "vue-property-decorator";
import Validation from "@/components/Validation.vue";
import { namespace } from "vuex-class";
import isEmpty from "lodash/isEmpty";

const AuthModule = namespace("auth");

@Component({
  components: {
    Validation
  }
})
export default class Login extends Vue {
  public name = "SupportUserTokenLogin";

  @AuthModule.Action("signIn")
  public signIn: any;

  @AuthModule.Getter("getError")
  public getError: any;

  async mounted() {
    await this.signIn({
      token: this.$route.params.token,
      authClient: "FSC_WEB"
    });
  }

  public get hasError(): boolean {
    return !isEmpty(this.getError);
  }
}
